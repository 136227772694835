<template>
  <v-container fluid>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listCompany.length > 0 ">
          <thead>
          <tr>
            <th></th>
            <th class="text-left">
              name
            </th>
            <th class="text-left">
              Phone
            </th>
            <th class="text-left">
              Email
            </th>
            <th class="text-left">
              Address
            </th>
            <th class="text-left">
              Status
            </th>
            <th class="text-left">

            </th>
          </tr>
          </thead>
          <tbody>
          <tr :key="index"
              v-for="(item, index) in listCompany"
          >
            <td>
              <div class="img-table-company">
                <img :src="item.url_Logo" alt="">
              </div>
            </td>
            <td>
              {{ item.name }}
              <p class="business_type">{{ item.typeBusiness }}</p>
            </td>
            <td>{{ item.phone }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.address }}</td>
            <td class="company-status">
              <span class="isClose" v-if="item.status ==='close'">{{ item.status }}</span>
            </td>
            <td>
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-h btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-switch style="text-transform: uppercase"
                              label="close"
                              inset
                              v-model="item.status"
                              @change="changeStatus(item.status,item.id)"
                    >
                    </v-switch>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <default-table-no-result v-else/>

        <Pagination
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchCompanyOffline"
        />

      </div>
    </div>
  </v-container>
</template>

<script>
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "../../../components/defaultTableNoResult";

export default {
  components: {
    Pagination,
    defaultTableNoResult
  },
  props: ['offline'],
  data() {
    return {
      listCompany: [],
      isLoading: true,
      offset: 10,
      pagination: {},
      per_page: 10,
    }
  },
  methods: {
    changeStatus(newValue, id) {
      if (newValue === false) {
        this.$axios.get(`admin/open-company/${id}`).then((res) => {
          if (res.status) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
            }, 300)
            this.fetchCompanyOffline()
          }
        })
      }
    },


    changeTabs(index) {
      this.activeIndex = index;
    },

    fetchCompanyOffline() {
      this.isLoading = true;
      this.$axios.get(`admin/company`, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          status: "close",
        }
      }).then((res) => {
        if (res) {
          this.isLoading = false;
          this.listCompany = res.data.data.data;
          this.pagination = res.data.data.pagination;
          if (!this.listCompany.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        }
      }).catch((error) => {
        console.log(error)
        this.isLoading = false;
      })
    },
  },

  watch: {
    offline() {
      this.fetchCompanyOffline();
    }
  },


  created() {
    this.fetchCompanyOffline();
  }
}
</script>

<style scoped lang="scss">
.isClose {
  background-color: rgba(214, 48, 49, 0.6);
  padding: 2px 18px;
  border-radius: 30px;
  color: #FFFFFF;
}
</style>
