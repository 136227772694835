<template>
  <v-dialog
      v-model="modalDeleteCompany"
      width="500"
      persistent
      @close="setModalDeleteCompany(false)"
      transition="scroll-y-transition">
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete">
        <v-list-item-title class="title-header-form ml-5">
          <span class="title-modal-delete">Delete Company</span>
        </v-list-item-title>
        <v-btn
            icon
            depressed
            @click="setModalDeleteCompany(false)"
        >
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="content-card-layout">
        <div class="main-content-card">
          <h4 class="ml-2 mb-10" style="font-weight: 400;color:red;">Do you want to delete ?</h4>
        </div>
      </div>
      <div class="section-footer-delete">
        <div class="btn-section">
          <v-btn class="btn-cancel" outlined @click="setModalDeleteCompany(false)">
            cancel
          </v-btn>
          <v-btn class="btn-action cancel" @click="DeleteItem(companyId)" :loading="btnLoading">
            Delete
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
    icon: {
      default: 'mdi-close',
      type: String
    },
    companyId: {},
  },
  data() {
    return {
      btnLoading: false,
      modalDeleteCompany: false,
    }
  },

  methods: {
    setModalDeleteCompany(isVisible) {
      this.modalDeleteCompany = isVisible;
      this.$emit('change', isVisible);
    },

    DeleteItem(companyId) {
      this.btnLoading = true;
      this.$axios.delete(`admin/company/${companyId}`).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.setModalDeleteCompany(false);
            this.$emit("success");
            this.$notification.OpenNotification_DeleteItem_OnSuccess('top-right', 'danger', 3000);
          }, 300)
        }
        this.btnLoading = false;
      })
    }

  },
  watch: {
    visible(val) {
      this.modalDeleteCompany = val;
    },
  },
}
</script>

<style scoped lang="scss">
.main-card-delete {
  height: 200px;
  color: #585858;

  .card-action-delete {
    padding-top: 30px;
  }
}

.section-footer-delete {
  position: absolute;
  bottom: 20px;
  right: 0;
}
</style>
