<template>
  <div class="main-container">
    <div class="tabs is-centered is-boxed">
      <ul>
        <li
          :class="{ 'is-active': activeIndex === index }"
          v-for="(tab, index) in tabsData"
          :key="index"
          @click="changeTabs(index)"
        >
          <a>
            <span class="icon is-small"><i class="fal fa-building"></i></span>
            <span class="label-input" v-if="$i18n.locale === 'en'">{{
              tab.name_en
            }}</span>
            <span class="label-input" v-else>{{ tab.name }}</span>
          </a>
        </li>
      </ul>
    </div>

    <!--    SECTION COMPANY ONLINE-->
    <div v-show="activeIndex === 0">
      <div class="section-topic">
        <div class="title-header">
          <h1>{{ $t("Company.companyName") }}</h1>
        </div>
      </div>
      <div class="section-action">
        <div class="search-action">
          <v-text-field
            class="btn btn-color btn-border btn-border-color"
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('Search.search')"
            v-model="searchItem"
            @keypress.enter="searchFilterItem"
          >
          </v-text-field>
        </div>
        <div class="btn-new-create-action">
          <v-btn class="btn-create" @click="onCreate()">
            {{ $t("Company.create") }}
          </v-btn>
        </div>
      </div>
      <div class="section-form-content">
        <div class="block-content">
          <table class="table table-view" v-if="listCompany.length > 0">
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-left">
                  {{ $t("Company.table.name") }}
                </th>
                <th class="text-left">
                  {{ $t("Company.table.qtyUser") }}
                </th>
                <th class="text-left">
                  {{ $t("Company.table.phone") }}
                </th>
                <th class="text-left">
                  {{ $t("Company.table.email") }}
                </th>
                <th class="text-left">
                  {{ $t("Company.table.address") }}
                </th>
                <th class="text-left">
                  {{ $t("Company.table.created_at") }}
                </th>
                <th class="text-left">
                  {{ $t("Company.table.status") }}
                </th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr :key="index" v-for="(item, index) in listCompany">
                <td>
                  <div class="img-table-company">
                    <img :src="item.url_Logo" alt="" />
                  </div>
                </td>
                <td>
                  {{ item.name }}
                  <p class="business_type">{{ item.typeBusiness }}</p>
                </td>
                <td>
                  {{ item.qty_user }}
                  <v-btn
                    icon
                    color="indigo"
                    @click="onDetail(item.id, item.name)"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>

                  <!-- <v-btn icon color="primary" @click="exportExcel(item.id)">
                    <v-icon> mdi-export </v-icon>
                  </v-btn> -->
                </td>
                <td>{{ item.phone }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.address }}</td>
                <td>{{ item.created_at }}</td>
                <td class="company-status">
                  <span class="isOpen" v-if="item.status === 'open'">{{
                    item.status
                  }}</span>
                </td>
                <td>
                  <v-menu transition="slide-y-transition" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn outlined v-bind="attrs" v-on="on">
                        <i class="fas fa-ellipsis-h btn-ellipsis"></i>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title
                          class="btn-edit"
                          @click="onEdit(item)"
                          >{{ $t("Company.action.edit") }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          class="btn-delete"
                          @click="onDeleted(item.id)"
                        >
                          {{ $t("Company.action.delete") }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-switch
                          style="text-transform: uppercase"
                          inset
                          label="open"
                          v-model="item.status"
                          @change="changeStatus(item.status, item.id)"
                        >
                        </v-switch>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </table>

          <default-table-no-result v-else />
          <Loading v-if="isLoading" />

          <Pagination
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchCompanyOnline"
          />
        </div>
      </div>
    </div>
    <!--    @SECTION COMPANY ONLINE-->

    <!--    SECTION COMPANY OFFLINE-->
    <div v-show="activeIndex === 1">
      <companyOffline :offline="offLine" />
    </div>
    <!--   END SECTION COMPANY OFFLINE-->

    <modalDeleteCompany
      :visible="modalDeleteCompany"
      :companyId="companyId"
      @success="fetchCompanyOnline"
      @change="(val) => (modalDeleteCompany = val)"
    />
  </div>
</template>

<script>
import modalDeleteCompany from "@/components/company/modal/modalDeleteCompany";
import companyOffline from "./companyOffline";
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";
import { mapActions } from "vuex";
import defaultTableNoResult from "@/components/defaultTableNoResult";

export default {
  components: {
    modalDeleteCompany,
    companyOffline,
    Loading,
    Pagination,
    defaultTableNoResult,
  },

  data() {
    return {
      isLoading: false,
      activeIndex: 0,
      companyStatus: false,
      modalDeleteCompany: false,
      companyId: "",
      company_id: "",
      listCompany: [],

      tabsData: [
        {
          name_en: "Company Online",
          name: "ບໍລິສັດທີ່ເປີດທຳການ",
        },
        {
          name_en: "Company Offline",
          name: "ບໍລິສັດທີ່ປິດທຳການ",
        },
      ],

      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,

      offLine: false,
    };
  },
  methods: {
    exportExcel(company_id) {
      this.isLoading = true;
      this.$axios
        .post(
          `admin/export/employee/${company_id}`,
          {},
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
            const fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute("download", "employee-holiday.xlsx");
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },

    onDetail(company_id, company_name) {
      this.$router
        .push({
          name: "company.user.detail",
          params: {
            company_id: company_id,
            company_name: company_name,
          },
        })
        .catch(() => {});
    },
    searchFilterItem() {
      this.fetchCompanyOnline();
    },
    ...mapActions({
      setCompanyItem: "Company/EditCompany",
    }),
    onCreate() {
      this.$router.push({ name: "company.create" }).catch(() => {});
    },
    onEdit(item) {
      // this.$store.dispatch("Company/EditCompany", item)
      this.setCompanyItem(item);
      this.$router.push({ name: "company.edit" }).catch(() => {});
    },
    onDeleted(company_id) {
      this.companyId = company_id;
      this.modalDeleteCompany = true;
    },

    changeStatus(newValue, id) {
      if (newValue === false) {
        this.$axios
          .get(`admin/close-company/${id}`)
          .then((res) => {
            if (res.data.success === true) {
              setTimeout(() => {
                this.$notification.OpenNotification_AddItem_OnSuccess(
                  "top-right",
                  "primary",
                  3000
                );
              }, 300);
              this.fetchCompanyOnline();
            }
          })
          .catch(() => {});
      }
    },

    changeTabs(index) {
      if (index === 1) {
        this.offLine = true;
        this.activeIndex = 1;
      } else {
        this.offLine = false;
        this.fetchCompanyOnline();
      }
      this.activeIndex = index;
    },

    fetchCompanyOnline() {
      this.isLoading = true;
      this.$axios
        .get(`admin/company`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
            status: "open",
          },
        })
        .then((res) => {
          if (res) {
            this.isLoading = false;
            this.listCompany = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listCompany.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },

  created() {
    this.fetchCompanyOnline();
  },
};
</script>

<style scoped lang="scss">
.company-status {
  .isOpen {
    background-color: rgba(0, 184, 148, 0.6);
    padding: 0.5px 10px;
    // border-radius: 3px;
    color: #ffffff;
    font-family: $font-family;
    font-size: 14px;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }
}
</style>
